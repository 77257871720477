$mobile: 450px;
$tabletAndBellow: 1024px;
$breakpointBigScreen: 1640px;

.videoWrap {
  position: relative;
  padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
  height: 0;
  overflow: hidden;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.mediaContainer {
  flex: 5;
  height: calc(100vh - 105px);
  position: relative;

  @media (max-width: $tabletAndBellow) {
  }
}


.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: var(--color-cream-40) !important;

  @media (max-width: $mobile) {
    min-width: 100vw;
    height: 50%;
  }
}

.playButton {
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 2;
}

.playButtonContainer {
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
