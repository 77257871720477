.titleContainer {
  display: flex;
  align-items: baseline;
  flex: 1;
}

.svgContainer {
  width: var(--spacing-10);
}

.svg {
  position: relative;
  top: calc(var(--spacing-3) - var(--spacing-8));
  width: var(--spacing-6);

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.imageContainer {
  max-width: 450px;
}
