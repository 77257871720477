@import "./styles/utils/fluidtype";

// Fluid typography
$minWidth: 320px;
$maxWidth: 1440px;

.table {
  width: 100%;
}

.th {
  padding-left: var(--spacing-4);
  padding-bottom: var(--spacing-6);
  text-align: left;

  &:nth-child(3) {
    color: var(--color-red-40);
  }

  @media (max-width: 600px) {
    padding-left: 0px;
  }
}

.td {
  padding: var(--spacing-4) var(--spacing-4);
  vertical-align: top;

  &:nth-child(2) {
    color: var(--color-grey-40);
    @include fluid-type($minWidth, 1000px, 16px, 20px);
  }

  &:nth-child(3) {
    color: var(--color-red-40);
  }

  @media (max-width: 600px) {
    padding: var(--spacing-2);
  }
}

.tdDescription {
  color: var(--color-grey-40);
  max-width: 20rem;

  // Breakpoint
  @media (max-width: 600px) {
    padding: 0px;
  }
}

.tr {
  cursor: pointer;

  &:nth-child(even) {
    background-color: var(--color-peach-40);
  }
}

.svg {
  margin-right: var(--spacing-4);
  @media (max-width: 600px) {
    display: none;
  }
}
