.grid {
  display: flex;
  margin: calc(var(--grid-gutter) * -1) 0 0 calc(var(--grid-gutter) * -1);

  div:first-child {
    flex: 1.5;
  }

  &-verticalItemsCenter {
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
    }
  }

  &-reverse {
    flex-flow: row-reverse;
  }

  // Breakpoint
  @media (max-width: 768px) {
    display: block;
    flex-flow: row; // Revert any inverted flow
  }
}

.gridItem {
  flex: 1;
  padding: calc(var(--grid-gutter) * 1) 0 0 calc(var(--grid-gutter) * 1);

  &-verticalCenter {
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
    }
  }

  &-horizontalCenter {
    display: flex;
    justify-content: center;
  }
}

.imageContainer {
  position: relative;

  .imageDeliveryTimeOverlayContainer {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.imageDeliveryTimeOverlay {
  float: right;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  text-align: right;
  border-radius: 0 0 0 var(--spacing-6);
  padding: var(--spacing-2) var(--spacing-5);
  background-color: var(--color-green-20);
  color: var(--color-red-40);

  @media screen and (max-width: 450px) {
    padding: var(--spacing-2) var(--spacing-3) var(--spacing-2) var(--spacing-5);
  }
}

.comingSoon {
  .imageDeliveryTimeOverlay {
    background-color: var(--color-yellow-40);
  }
}

.soldOut {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  .imageDeliveryTimeOverlay {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color-yellow-40);
  }
}

.bikeAvailabilityContainer {
  display: grid;
  grid-template-columns: var(--spacing-5) 1fr;
  align-items: center;
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-4);
}

.bikeAvailabilityIcon {
  margin-right: var(--spacing-3);
}

.priceContainer {
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-6);

  .reverse {
    flex-flow: row-reverse;
  }

  @media screen and (max-width: 1080px) and (min-width: 768px) {
    flex-direction: column;
    gap: var(--spacing-3);
  }
}

.link {
  color: var(--color-red-40);
}

.underline {
  color: var(--color-green-50);
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
