.container {
  a {
    color: var(--color-black-40);
  }
}

@media (max-width: 767px) {
  .container {
    display: flex;
    flex-direction: column;
  }
  .container div:last-child {
    order: -1;
  }
  .imageContainer {
    display: none;
  }
}
