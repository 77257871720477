.container {

  @media (max-width: 600px) {
    margin-top: var(--spacing-6);
  }
}

.contentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    display: block;
  }
}
.boxCtaContainer {
  @media (max-width: 600px) {
    margin-top: var(--spacing-8);
  }
}

.article {
  width: 50%;
  @media (max-width: 600px) {
    width: 100%;
  }
}
