.svg {
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
}

.mobileSvg {
  display: none;
  margin-bottom: -2px;
  @media (max-width: 600px) {
    display: block;
  }
}
