.boxLeft {
  // remove this padding to make images bigger
  //padding: var(--spacing-6) var(--spacing-8);

  @media (max-width: 767px) {
    padding: 0;
    margin-bottom: var(--spacing-6);
  }
}

.boxBgColor-yellow {
  background: var(--color-yellow-40);
}

.boxBgColor-peach {
  background: var(--color-peach-40);
}

.boxBgColor-green {
  background: var(--color-green-40);
  color: var(--color-cream-40);
  a {
    color: var(--color-cream-40);
  }
}
