$breakpointUp: 770px;
$breakpointBigScreen: 1640px;
$mediumBreakpoint: 767px;

.container {
    position: relative;

    @media (min-width: $breakpointBigScreen) {
        max-width: var(--page-width-max);
        margin: 0 auto;
    }
}

.titleContainer {
    position: absolute;
    top: 1px;
    left: 10%;
    color: var(--color-black-40);
}

.buttonContainer {
    display: flex;
    justify-content: center;

    // Breakpoint
    @media (min-width:1025px) {
        position: absolute;
        bottom: 1%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
