.container {
  position: relative;
  margin-top: var(--spacing-8);
}

.flexContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column !important;
  }
}

.contentContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: var(--spacing-4);
  padding-right: var(--spacing-4);

  @media screen and (max-width: 768px) {
    position: relative;
    flex-direction: column !important;
    align-items: center;
    padding-left: var(--spacing-6);
    padding-right: var(--spacing-6);
    z-index: 1 !important;
  }
}

.imageContainer {
  flex: 1;
  max-width: 350px;
  margin-left: var(--spacing-8);
  margin-right: var(--spacing-8);

  @media screen and (max-width: 768px) {
    margin-top: var(--spacing-6);
    margin-bottom: var(--spacing-6);
  }
}

.boxContainer {
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.svgContainer {
  height: 100%;
  width: 750px;

  @media screen and (max-width: 768px) {
    position: absolute;
    width: 100%;
  }
}

.svgDesktop {
  width: 750px;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.svgMobile {
  width: 100%;
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    max-height: 100%;
  }
}
