.container {
  width: 100%;
  text-align: left;
}

.row {
  display: flex;
  flex-flow: row !important;
  //justify-content: center;
  align-items: center;
  padding: var(--spacing-3);
  padding-left: var(--spacing-4);
  margin-top: var(--spacing-2);
}

.bgColor-yellow-40 {
  background: var(--color-yellow-40);
  a {
    color: var(--color-black-40);
    text-decoration-color: var(--color-red-40);

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--color-black-40);
    }
  }
}

.bgColor-peach-40 {
  background: var(--color-peach-40);
  a {
    color: var(--color-black-40);
    text-decoration-color: var(--color-red-40);
    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--color-black-40);
    }
  }
}

.bgColor-green-40 {
  background: var(--color-green-40);
  color: var(--color-cream-40);
  a {
    color: var(--color-cream-40);
  }
}

.svg {
  margin-right: var(--spacing-4);
  width: var(--spacing-5);
}
