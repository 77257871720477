$mobile: 450px;
$tabletAndBellow: 1024px;
$breakpointBigScreen: 1640px;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-peach-20);
  //height: calc(100vh - 105px);
  height: 100%;

  // Tilted smartphone to landscape
  @media screen and (min-width: 320px) and (max-width: 815px) and (orientation: landscape) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  // Breakpoint
  @media (max-width: $tabletAndBellow) {
    flex-direction: column;
  }

  @media (max-width: $mobile) {
    flex-direction: column;
  }

  @media (min-width: $breakpointBigScreen) {
  }
}

.content {
  flex: 3;
  text-align: left;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-8);

  &-center {
    text-align: center;
  }

  // Breakpoint
  @media (max-width: $tabletAndBellow) {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin-right: 0;
    padding: var(--spacing-6);
    margin-bottom: var(--spacing-6);
  }

  @media (min-width: $breakpointBigScreen) {
  }
}

.logo {
  @media (max-width: 480px) {
    width: 50%;
  }
}
