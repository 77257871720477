.container {
  width: 100%;
  padding: var(--spacing-1);
}

.item {
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-top: var(--spacing-5);
  margin-bottom: var(--spacing-3);
}

.separator {
  height: 2px;
  width: 100%;
  background-color: var(--color-peach-40);
}

.svg {
  transform:  rotate(0deg);
  transition-duration: 200ms;
}

.svgTransform {
  transform: rotate(90deg);
  transition-duration: 200ms;
}
