.contentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: var(--spacing-6);
  border-radius: 18% 29% 29% 19% / 39% 58% 41% 23%;
  text-align: left;
  background-color: var(--color-peach-30);
  top: -30px;
  position: relative;
  align-items: flex-start;

  @media (max-width: var(--screen-tablet)) {
    padding: var(--spacing-4) var(--spacing-6) var(--spacing-6) var(--spacing-6);
    border-radius: 32% 37% 29% 42% / 20% 25% 35% 35%;
    flex-direction: column;
  }
}


.image {
  z-index: 2;
  position: absolute;
  left: var(--spacing-8);
}

.imageContainer {
    height: 110px;
    position: relative;

}
