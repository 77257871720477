.button {
  background-color: var(--color-green-40);
  border-radius: 1000px;

  &:hover,
  &:disabled {
    cursor: pointer;
    background-color: var(--color-green-30);
    border-color: var(--color-green-30);
  }
}
