
.gridContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.gridItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: var(--spacing-6);
  border-radius: var(--spacing-3);

  a {
    text-decoration: none;
  }
}

.boxBgColor-yellow-40 {
  background: var(--color-yellow-40);
  a {
    color: var(--color-black-40);
    text-decoration-color: var(--color-red-40);

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--color-black-40);
    }
  }
}

.boxBgColor-yellow-30 {
  background: var(--color-yellow-30);
  a {
    color: var(--color-black-40);
    text-decoration-color: var(--color-red-40);

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--color-black-40);
    }
  }
}

.boxBgColor-peach-40 {
  background: var(--color-peach-40);
  a {
    color: var(--color-black-40);
    text-decoration-color: var(--color-red-40);

    &:hover {
      text-decoration: underline;
    }
  }
}

.boxBgColor-peach-30 {
  background: var(--color-peach-30);
  a {
    color: var(--color-black-30);
    text-decoration-color: var(--color-red-30);

    &:hover {
      text-decoration: underline;
    }
  }
}

.boxBgColor-green-40 {
  background: var(--color-green-40);
  color: var(--color-cream-40);
  a {
    color: var(--color-cream-40);

    &:hover {
      text-decoration: underline;
    }
  }
}
.boxBgColor-green-30 {
  background: var(--color-green-30);
  color: var(--color-red-40);
  a {
    color: var(--color-red-40);

    &:hover {
      text-decoration: underline;
    }
  }
}
.boxBgColor-green-20 {
  background: var(--color-green-20);
  color: var(--color-red-40);

  a {
    color: var(--color-red-40);
    &:hover {
      text-decoration: underline;
    }
  }
}
