.container {
  box-shadow: 0px 2px 20px rgba(55, 55, 55, 0.08);
  border-radius: var(--spacing-3);
  padding: var(--spacing-6) calc(var(--spacing-8) + var(--spacing-8));

  @media screen and (max-width: 768px) {
    padding: var(--spacing-6) var(--spacing-6);
  }
}

.locationsContainer {
  //width: 60%;

}

.location {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-2) var(--spacing-5);
  border-radius: var(--spacing-2);
  background-color: var(--color-peach-30);
  cursor: pointer;
}
// Faux checkbox
.checkbox {
  cursor: pointer;
  &__input {
    // Visually hidden
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    border: 0;

    // Toggle
    &:checked + .checkbox__label::after {
      transform: scale(1);
    }

    // Set default focus style - visual aid
    &:focus + .checkbox__label::before {
      border-color: var(--color-black-40);
    }
  }

  &__label {
    display: inline-block;
    margin-bottom: 0;
    padding-left: 28px;
    padding-top: 28px;
    position: relative;
    font-size: 20px;

    &:before {
      content: "";
      border: 2px solid var(--color-grey-40);
      width: 30px;
      height: 30px;
      display: block;
      position: absolute;
      left: 0px;
      top: 4px;
      border-radius: var(--border-radius);
      background-color: var(--color-cream-40);
    }

    &:after {
      content: "✓";
      font-weight: bold;
      font-size: 18px;
      display: flex;
      color: var(--color-cream-40);
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 4px;
      transition: all 0.05s ease-out;
      transform: scale(0);
      width: 30px;
      height: 30px;
      background-color: var(--color-green-40);
      border-radius: var(--border-radius);
    }
  }
}
