.imageContainer {
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

.img {
  display: flex;
  justify-content: center;
}
