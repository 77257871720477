@media (min-width: 768px) {
  // Add reverse first row layout on desktop
  .reverse-first div:nth-child(1) {
    flex-flow: row-reverse;
  }
}

// Add spacing between list items
.cardGrid > div:not(:last-child) {
  margin-bottom: var(--spacing-11);

  @media (max-width: 768px) {
    margin-bottom: var(--spacing-9);
  }
}

@media (max-width: 767px) {
  // Remove some of default spacing between list item image and text
  .cardGrid > div > div:last-child {
    padding-top: var(--spacing-4);
  }
}

@media (min-width: 768px) {
  // Add alternating layout on desktop
  .reverse > div:nth-child(even) {
    flex-flow: row-reverse;
  }
}
