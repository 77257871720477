.box {
  padding: var(--spacing-6) var(--spacing-8) var(--spacing-7) var(--spacing-8);

  @media (max-width: 767px) {
    padding: var(--spacing-4) var(--spacing-6) var(--spacing-6) var(--spacing-6);
  }
}

.boxBgColor-yellow-40 {
  background: var(--color-yellow-40);
  a {
    color: var(--color-black-40);
    text-decoration-color: var(--color-red-40);

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--color-black-40);
    }
  }
}

.boxBgColor-yellow-30 {
  background: var(--color-yellow-30);
  a {
    color: var(--color-black-40);
    text-decoration-color: var(--color-red-40);

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--color-black-40);
    }
  }
}

.boxBgColor-peach-40 {
  background: var(--color-peach-40);
  a {
    color: var(--color-black-40);
    text-decoration-color: var(--color-red-40);
    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--color-black-40);
    }
  }
}

.boxBgColor-peach-30 {
  background: var(--color-peach-30);
  a {
    color: var(--color-black-40);
    text-decoration-color: var(--color-red-30);
    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--color-black-40);
    }
  }
}

.boxBgColor-green-40 {
  background: var(--color-green-40);
  color: var(--color-cream-40);
  a {
    color: var(--color-cream-40);
  }
}
.boxBgColor-green-30 {
  background: var(--color-green-30);
  color: var(--color-cream-40);
  a {
    color: var(--color-cream-40);
  }
}
.boxBgColor-green-20 {
  background: var(--color-green-20);
  color: var(--color-cream-40);
  a {
    color: var(--color-cream-40);
  }
}

.boxBgColor-grey-40 {
  background: var(--color-grey-40);
  color: var(--color-cream-40);
  a {
    color: var(--color-cream-40);
  }
}

.boxBgColor-grey-30 {
  background: var(--color-grey-30);
  color: var(--color-black-40);
  a {
    color: var(--color-black-40);
  }
}
.boxBgColor-grey-30 {
  background: var(--color-grey-30);
  color: var(--color-black-40);
  a {
    color: var(--color-black-40);
  }
}

.box-small-padding {
  padding: var(--spacing-6) var(--spacing-4);
}
