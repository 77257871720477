.box {
  @media (max-width: 600px) {
    display: block;
  }
}

.boxItem {
  padding: var(--spacing-6) var(--spacing-8) var(--spacing-7) var(--spacing-8);
  @media (max-width: 767px) {
    padding: var(--spacing-4) var(--spacing-6) var(--spacing-6) var(--spacing-6);
  }
}

.circle{
  display: flex;
  align-items: center;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  text-align: center;

  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;


  @media (max-width: 600px) {
    width: 300px;
    height: 300px;
  }
}

.boxBgColor-yellow {
  background: var(--color-yellow-40);
  a {
    color: var(--color-black-40);
    text-decoration-color: var(--color-red-40);

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--color-black-40);
    }
  }
}

.boxBgColor-peach {
  background: var(--color-peach-40);
  a {
    color: var(--color-black-40);
    text-decoration-color: var(--color-red-40);
    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--color-black-40);
    }
  }
}

.boxBgColor-green {
  background: var(--color-green-40);
  color: var(--color-cream-40);
  a {
    color: var(--color-cream-40);
  }
}

