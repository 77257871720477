.number {
  display: inline-block;
  flex-flow: inherit !important;
  background: var(--color-peach-40);
  color: var(--color-red-40);
  width: 1.5em;
  height: 1.5em;
  min-width: 1.5em; // to avoid flexbox shrink
  line-height: 1.5em;
  text-align: center;
  border-radius: 10%;
  margin-right: var(--spacing-4);
  font-family: "Walsheim-bold", sans-serif ;
}

.title {
  font-family: "Walsheim-medium", sans-serif ;
}

.grid {
  display: flex;
  flex-direction: row;
  margin: calc(var(--grid-gutter) * -1) 0 0 calc(var(--grid-gutter) * -1);

  &-vertical {
    flex-direction: column;
  }
  // Breakpoint
  @media (max-width: 1100px) {
    display: block;
  }
}

.gridItem {
  flex: 1;
  padding: calc(var(--grid-gutter) * 0.8) 0 0 calc(var(--grid-gutter) * 1);
}

.gridItemHeaderContainer {
  display: flex;
  flex-direction: row;
  flex-flow: inherit !important;
  align-items: center;
}
