.linksContainer {
  padding-top: var(--spacing-4);

  &:nth-child(odd) {
    color: var(--color-red-40);
    cursor: pointer;
  }
}

.link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
