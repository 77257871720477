.box {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.boxBgColor-yellow {
  background: var(--color-yellow-40);
  a {
    color: var(--color-black-40);
    text-decoration-color: var(--color-red-40);

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--color-black-40);
    }
  }
}

.boxBgColor-peach {
  background: var(--color-peach-40);
  a {
    color: var(--color-black-40);
    text-decoration-color: var(--color-red-40);
    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--color-black-40);
    }
  }
}

.boxBgColor-green {
  background: var(--color-green-40);
  color: var(--color-cream-40);
  a {
    color: var(--color-cream-40);
  }
}
